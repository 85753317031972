/* Import the Dongle font */
@import url('https://fonts.googleapis.com/css2?family=Dongle:wght@300;400;700&display=swap');

body {
  background-color: #87CEEB; /* Sky blue background */
  margin: 0;
  padding: 0;
}

.app {
  font-family: 'Dongle', sans-serif;
  min-height: 100vh;
  padding: 20px;
  position: relative;
}

.logo-container {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 10;
}

.company-logo {
  width: 200px; /* Adjust size as needed */
  height: auto;
}

.header {
  background-color: #282c34;
  color: white;
  padding: 20px;
  padding-left: 140px; /* Make space for the logo */
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header h1 {
  margin: 0;
  font-size: 2.5rem;
}

.create-campaign-btn {
  background-color: #61dafb;
  color: #282c34;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 5px;
}

.create-campaign-btn:hover {
  background-color: #4fa8d5;
}

.content {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.left-panel, .right-panel {
  width: 48%;
  border: 1px solid #000;
  padding: 20px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

/* Responsive design */
@media (max-width: 768px) {
  .header {
    flex-direction: column;
    align-items: flex-start;
    padding-left: 20px;
  }

  .create-campaign-btn {
    margin-top: 10px;
  }

  .content {
    flex-direction: column;
  }

  .left-panel, .right-panel {
    width: 100%;
    margin-bottom: 20px;
  }

  .logo-container {
    position: static;
    margin-bottom: 20px;
  }
}